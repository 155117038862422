import React from "react";
import InfoContactUsForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberLpBestPractices = process.env.GATSBY_SUBSCRIBER_LP_BEST_PRACTICES_FOR_IMPLEMENTING_CHANGING_YOUR_CX_SUPPORT_FORM_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>
          Best Practices for Implementing or Changing Your CX Support Solution
        </h3>
        <h1>
          Watch 729's Customer Support Webinar to share in the experience of our
          120 successful CX implementations.
        </h1>
        <p>
          Learn practical tips, real world challenges and techniques to improve
          your agent and customer experiences.
        </p>
        <p>In this On-demand Webinar we will cover:</p>
        <ul>
          <li>
            <i>Migrating from your old tool</i> - pitfalls, cut overs and how to
            evaluate your data
          </li>
          <li>
            <i>Workflows</i> - should you replace or create new ones?
          </li>
          <li>
            <i>Agent Resourcing</i> - the value of a knowledge base to quickly
            handle tickets
          </li>
          <li>
            <i>Agent groups</i> - how to manage different teams in one tool
          </li>
          <li>
            <i>Integrations</i> - best practices for wiring up all your tools
          </li>
          <li>
            <i>User experience</i> - how to increase self serve without
            decreasing value
          </li>
          <li>
            <i>Workflow efficiency</i> - how to learn and iterate quickly and
            get rid of those old excel spreadsheets!
          </li>
          <li>
            <i>Reporting and optimization</i> - how to use data to make better
            business decisions and show stakeholders what they need to see
          </li>
        </ul>
        <p>
          <strong>
            If you are thinking of switching CX tools, this will be an
            invaluable hour to avoid making some common mistakes and setting
            yourself up for success.{" "}
          </strong>
        </p>
        <p>
          If you don’t have time to listen in, you can always take our do it
          yourself Workflow Efficiency test anytime{" "}
          <a href="/zendesk-workflow-guide" target="_blank" rel="noreferrer">
            HERE
          </a>
        </p>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h3 className="text-center text-white">
          Sign Up and View the Webinar Now!
        </h3>
        <div className="bg-light p-4 bg-light mt-3">
          <InfoContactUsForm
            buttonText="WATCH THE WEBINAR"
            location={location}
            tags={[subscriberLpBestPractices]}
            onSubmitSuccess={() => navigate('/lp/best-practices-for-implementing-changing-your-cx-support-solution-on-demand-ty')}/>
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying regarding our Zendesk work</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed. They are personable, caring, and reliable. I would recommend working with them, and feel confident everyone would feel the same value we did."
            name="KAYLA FISCHER"
            company="HireVue, Inc"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial/>
  </Layout>
);

export default Index;
